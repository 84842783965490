import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class RunningProjectsV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Area Properties</h6>
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color text-center">Our Completed Projects</h6>
                <h1 className="section-title text-center">
                  Completed Projects
                </h1>
              </div> */}
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Completed Projects
                </h6>
                <h1 className="section-title">Completed Projects</h1>
              </div>
            </div>
          </div>
          <div className="row go-top">
            <div className="col-lg-6">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <Link to="/genx-landmark-details">
                    <img
                      src={publicUrl + "assets/img/product-3/GENX LANDMARK.png"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="search-by-place-info">
                  {/* <h6><Link to="/contact">Patna, Bihar</Link></h6> */}
                  <h4>
                    <Link to="/genx-landmark-details">GenX Landmark</Link>
                  </h4>
                  {/* <div className="search-by-place-btn">
			              <Link to="#">View Property <i className="flaticon-right-arrow" /></Link>
			            </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <Link to="/genx-icon-details">
                    <img
                      src={publicUrl + "assets/img/product-3/GENX ICON.png"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="search-by-place-info">
                  {/* <h6><Link to="/contact">Patna, Bihar</Link></h6> */}
                  <h4>
                    <Link to="/genx-icon-details">GenX Icon </Link>
                  </h4>
                  {/* <div className="search-by-place-btn">
			              <Link to="#">View Property <i className="flaticon-right-arrow" /></Link>
			            </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <Link to="/genx-lotus-details">
                    <img
                      src={publicUrl + "assets/img/product-3/GENX LOTUS.png"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="search-by-place-info">
                  {/* <h6><Link to="/contact">Patna, Bihar</Link></h6> */}
                  <h4>
                    <Link to="/genx-lotus-details">GenX Lotus</Link>
                  </h4>
                  {/* <div className="search-by-place-btn">
			              <Link to="#">View Property <i className="flaticon-right-arrow" /></Link>
			            </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <Link to="/genx-ramashram-details">
                    <img
                      src={
                        publicUrl + "assets/img/product-3/GENX RAMASHRAM.png"
                      }
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="search-by-place-info">
                  {/* <h6><Link to="/contact">Patna, Bihar</Link></h6> */}
                  <h4>
                    <Link to="/genx-ramashram-details">GenX Ramashram</Link>
                  </h4>
                  {/* <div className="search-by-place-btn">
			              <Link to="#">View Property <i className="flaticon-right-arrow" /></Link>
			            </div> */}
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default RunningProjectsV2;
