import React, { useState } from 'react';

export default function ModalBrochureForm({ showModal, handleClose }) {

    const handleDownloadSample = () => {
        console.log("Clicking to download sample file.")
        const link = document.createElement('a');
        const baseUrl = 'http://34.227.25.242/appapi/'; // Use BASE_URL for dynamic path
        console.log("Base url ", baseUrl);
        link.href = `${baseUrl}public/uploads/samplcsv/brochure.pdf`; // Correct URL for the file

        // link.href = `${process.env.PUBLIC_URL}/assets/files/user-add.csv`; // Use PUBLIC_URL for dynamic path
        link.download = 'brochure.pdf'; // Filename to download as
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            {/* Modal structure */}
            <div className={`modal fade ${showModal ? 'show d-block' : ''}`} tabIndex="-1" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none', zIndex: 9999 }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex flex-row justify-content-between'>
                                <h2 className="fw-bold">Personal Info</h2>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleClose}
                                ></button>
                            </div>

                            <form className="ltn__form-box">
                                <input type="text" id='name' name="name" placeholder="Name" />
                                <input type="text" id='mobile' name="mobile" placeholder="Mobile" />
                                <input type="text" id='email' name="email" placeholder="Email
                                " />
                                <button onClick={handleDownloadSample} type='button' className="theme-btn-1 btn reverse-color btn-block">SUBMIT</button>

                            </form>
                        </div>

                    </div>
                </div>
            </div>

            {/* Modal backdrop */}
            <div className="modal-backdrop fade show"></div>
        </div>
    );
}
