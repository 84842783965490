import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img
                        src={publicUrl + "assets/img/Transparent White Logo.png"}
                        // src={publicUrl + "assets/img/Black Logo.png"}
                        alt="Logo"
                      />
                    </div>
                  </div>
                  <p>
                    We build communities and dreams. With a commitment to
                    excellence, innovation, and sustainability, we provide
                    world-class residential and commercial properties that stand
                    as landmarks of modern living.
                  </p>

                  <div className="ltn__social-media mt-20">
                    <Social />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/about" className="text-white">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" className="text-white">
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" className="text-white">
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms-conditions" className="text-white">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy" className="text-white">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Projects</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/project-details" className="text-white">
                          GenX Daffodils
                        </Link>
                      </li>
                      <li>
                        <Link to="/genx-pearl-details" className="text-white">
                          GenX Pearl City
                        </Link>
                      </li>
                      <li>
                        <Link to="/genx-platinum-details" className="text-white">
                          GenX Platinum
                        </Link>
                      </li>
                      <li>
                        <Link to="/genx-landmark-details" className="text-white">
                          GenX Landmark
                        </Link>
                      </li>
                      <li>
                        <Link to="/genx-city-details" className="text-white">
                          GenX City
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="footer-widget footer-newsletter-widget">
                  <h4 className="footer-title">Address</h4>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            402, 4 th Floor, Genx Icon,
                            <br /> Mangalam Colony More,
                            <br /> Bailey Road, Danapur,
                            <br /> Patna- 801503
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+91-9709051000" className="text-white">
                              +91-9709051000
                              <br /> +91-9709052000
                            </a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a
                              href="mailto:genxinfracon01@gmail.com"
                              className="text-white"
                            >
                              genxinfracon01@gmail.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>

                  {/* <h5 className="mt-30">We Accept</h5>
                  <img
                    src={publicUrl + "assets/img/icons/payment-4.png"}
                    alt="Payment Image"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    );
  }
}

export default Footer_v1;
