import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Testimonial extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Testimonial
                </h6>
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    I’m incredibly pleased with the service I received from GEN
                    X for my home purchase. Home buying process can be daunting,
                    but their entire team was fantastic every step of the way.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/Client/4.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Shalini Mishra</h5>
                      {/* <label>Selling Agents</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    From finding potential properties to helping me negotiate an
                    ideal contract, they were always available and communicated
                    how each part of the whole process would move forward. They
                    also profoundly understood the housing market in my area,
                    which allowed us to get an incredible deal that exceeded my
                    expectations.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/Client/2.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Atul Kumar</h5>
                      {/* <label>Selling Agents</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Beyond their expertise and professionalism, the team was
                    amicable and kind throughout my home buying process. They
                    answered all my questions with in-depth explanations,
                    ensuring I fully understood what I was doing and why it was
                    necessary.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/Client/3.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Prabhakar Singh</h5>
                      {/* <label>Selling Agents</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    I’m so glad I chose GEN X for my real estate process needs.
                    Their knowledge and experience, combined with their patient
                    approach, made this otherwise stressful event a smooth and
                    successful process. I highly recommend them to anyone
                    looking for top-notch real estate services!
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/Client/1.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Rahul Pandey</h5>
                      {/* <label>Selling Agents</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
