import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
// import TermsConditions from "../terms";

class TermsConditions extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                        <div className="ltn__team-details-member-info text-center mb-40">
                            <div className="team-details-img">
                                <img src={publicUrl + "assets/img/team/4.jpg"} alt="Team Member Image" />
                            </div>
                          
                        </div>
                    </div> */}
            <div className="col-lg-12">
              <div className="ltn__team-details-member-info-details">
                <h3>Terms and Conditions</h3>
                <div>
                  <h5>Welcome to Genx Real Estate</h5>
                  <p>
                    By accessing or using the Genx Real Estate website, you
                    agree to be bound by these Terms and Conditions. Please read
                    them carefully before using our services.
                  </p>
                </div>
                <div>
                  <h5>Acceptance of Terms</h5>
                  <p>
                    By using our website, you acknowledge that you have read,
                    understood, and agree to these Terms and Conditions. If you
                    do not agree, please refrain from using our website.
                  </p>
                </div>

                <div>
                  <h5>Property Listings</h5>
                  <p>
                    All property information provided on this website is for
                    informational purposes only. While we strive for accuracy,
                    Genx Real Estate does not guarantee the completeness,
                    reliability, or availability of any property listing.
                  </p>
                </div>

                <div>
                  <h5>Use of Content</h5>
                  <p>
                    All content on this website, including text, images, and
                    logos, is the property of Genx Real Estate and is protected
                    by intellectual property laws. Unauthorized use or
                    reproduction is prohibited.
                  </p>
                </div>

                <div>
                  <h5>Third-Party Links</h5>
                  <p>
                    Our website may contain links to third-party websites. We
                    are not responsible for the content, privacy policies, or
                    practices of these third-party websites.
                  </p>
                </div>

                <div>
                  <h5>Limitation of Liability</h5>
                  <p>
                    Genx Real Estate is not liable for any direct, indirect,
                    incidental, or consequential damages arising from the use of
                    this website or reliance on its content.
                  </p>
                </div>

                <div>
                  <h5>Privacy Policy</h5>
                  <p>
                    Your use of this website is also governed by our Privacy
                    Policy, which outlines how we collect, use, and protect your
                    personal information.
                  </p>
                </div>

                <div>
                  <h5>Changes to Terms</h5>
                  <p>
                    Genx Real Estate reserves the right to modify these Terms
                    and Conditions at any time. Changes will be effective
                    immediately upon posting on this page.
                  </p>
                </div>

                <div>
                  <h5>Governing Law</h5>
                  <p>
                    These Terms and Conditions are governed by the laws of [Your
                    Jurisdiction]. Any disputes will be resolved in the courts
                    of [Your Jurisdiction].
                  </p>
                </div>

                <div>
                  <h5>Contact Information</h5>
                  <p>
                    For questions or concerns regarding these Terms and
                    Conditions, please contact us at:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <strong>Email: </strong>genxinfracon01@gmail.com
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Phone: </strong>+91-9709051000, +91-9709052000
                      </p>
                    </li>
                  </ul>
                </div>

                {/* <div className="row">
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Positions:</strong> Senior Property Seller
                        </li>
                        <li>
                          <strong>Experience:</strong> 10+ Years
                        </li>
                        <li>
                          <strong>Location:</strong> 13/A, NYC
                        </li>
                        <li>
                          <strong>Practice Area:</strong> Property Seller
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Email:</strong> example@example.com
                        </li>
                        <li>
                          <strong>Fax:</strong> +0123-456 789
                        </li>
                        <li>
                          <strong>Phone:</strong> +0123-456 789
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* <hr /> */}
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Sed ut perspiciatis unde omnis
                  iste natus error sit voluptatem accusantium doloremque
                  laudantium, totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                  aspernatur aut odit aut fugit, sed quia consequu ntur magni
                  dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
                  quisquam est, qui dolorem ipsum quia dolor sit amet,
                  consectetur, adipisci velit, sed quia non numquam eius modi
                  tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p> */}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsConditions;
