import React from 'react';
import PageHeader from './global-components/page-header';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import UpComingProjectsList from './section-components/upcoming-projects-list';
import GenXAryaSection from './shop-components/genx-arya-details';
import ProductSliderV1 from './shop-components/product-slider-v1';

const GenXArya = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Completed Projects" />
        <ProductSliderV1 />
        <GenXAryaSection />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default GenXArya

