import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import TeamDetails from './section-components/team-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import PrivacyDetails from './section-components/privacy-details';

const PrivacyPolicy = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Privacy Policy" />
        {/* <TeamDetails /> */}
        <PrivacyDetails />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default PrivacyPolicy

