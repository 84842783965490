import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/others/13.png"}
                  alt="About Us Image"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={publicUrl + "assets/img/others/8.png"}
                      alt="video popup bg image"
                    />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="#"
                      data-rel="lightcase:myCollection"
                    >
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    Building the Future of Real Estate
                  </h1>
                  <p>
                    At Genx, we believe in more than just constructing
                    buildings; we build communities and dreams. With a
                    commitment to excellence, innovation, and sustainability, we
                    provide world-class residential and commercial properties
                    that stand as landmarks of modern living.s
                  </p>
                </div>
                {/* <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Smart Home Design
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    Beautiful Scene Around
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    Exceptional Lifestyle
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    Complete 24/7 Security
                  </li>
                </ul> */}
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    <strong>Our Vision</strong> <br />
                    To revolutionize the real estate industry by delivering
                    properties that blend luxury, functionality, and
                    sustainability.
                  </p>
                </div>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    <strong>Our Mission</strong> <br/> To create a lasting impact on urban living by
                    providing high-quality residential and commercial spaces
                    that meet the evolving needs of our customers.
                  </p>
                </div>
                {/* <div className="btn-wrapper animated">
                  <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                    OUR SERVICES
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV4;
