import React from 'react';
import PageHeader from './global-components/page-header';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import UpComingProjectsList from './section-components/upcoming-projects-list';
import UpcomingProjectList2 from './shop-components/UpcomingProjectList2';

const UpcomingProjects2 = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Upcoming Projects" />
        <UpcomingProjectList2 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default UpcomingProjects2

