import React, { Component } from "react";
import { Link } from "react-router-dom";

class CopyRight extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__copyright-area ltn__copyright-2 section-bg-7 plr--5">
        <div className="container-fluid ltn__border-top-2">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center">
              <div className="ltn__copyright-design">
                <p className="text-white">
                  All Rights Reserved @ Genx{" "}
                  <span className="current-year">2024</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

{
  /* <span className="current-year" /> */
}

export default CopyRight;
