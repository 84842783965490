import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class TestimonialV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-65"
        data-bs-bg={publicUrl + "assets/img/bg/23.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color--- white-color">
                  Client,s Testimonial
                </h6>
                <h1 className="section-title white-color">
                  See What,s Our Client <br />
                  Says About Us
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-6-active slick-arrow-3">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
                <div className="ltn__testimoni-info">
                  <div className="ltn__testimoni-author-ratting">
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img src={publicUrl + "assets/Client/4.png"} alt="#" />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Shalini Mishra</h5>
                        {/* <label>Selling Agents</label> */}
                      </div>
                    </div>
                    <div className="ltn__testimoni-rating">
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    I’m incredibly pleased with the service I received from GEN
                    X for my home purchase. Home buying process can be daunting,
                    but their entire team was fantastic every step of the way.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
                <div className="ltn__testimoni-info">
                  <div className="ltn__testimoni-author-ratting">
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img src={publicUrl + "assets/Client/2.png"} alt="#" />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Atul Kumar</h5>
                        {/* <label>Selling Agents</label> */}
                      </div>
                    </div>
                    <div className="ltn__testimoni-rating">
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    I’m so glad I chose GEN X for my real estate process needs.
                    Their knowledge and experience, combined with their patient
                    approach, made this otherwise stressful event a smooth and
                    successful process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
                <div className="ltn__testimoni-info">
                  <div className="ltn__testimoni-author-ratting">
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img
                          src={publicUrl + "assets/img/testimonial/2.jpg"}
                          alt="#"
                        />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>Kelian Anderson</h5>
                        {/* <label>Selling Agents</label> */}
                      </div>
                    </div>
                    <div className="ltn__testimoni-rating">
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    From finding potential properties to helping me negotiate an
                    ideal contract, they were always available and communicated
                    how each part of the whole process would move forward. They
                    also profoundly understood the housing market in my area,
                    which allowed us to get an incredible deal that exceeded my
                    expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7 ltn__testimonial-item-8">
                <div className="ltn__testimoni-info">
                  <div className="ltn__testimoni-author-ratting">
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img src={publicUrl + "assets/Client/3.png"} alt="#" />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>James Carter</h5>
                        {/* <label>Selling Agents</label> */}
                      </div>
                    </div>
                    <div className="ltn__testimoni-rating">
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p>
                    I’m so glad I chose GEN X for my real estate process needs.
                    Their knowledge and experience, combined with their patient
                    approach, made this otherwise stressful event a smooth and
                    successful process. I highly recommend them to anyone
                    looking for top-notch real estate services!
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialV2;
