import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class OngoingProjectList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <div className="ltn__product-area ltn__product-gutter mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content ">
                  <div className="" id="liton_product_list">
                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                      <div className="row">
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/genx-daffodils.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/project-details">
                                      GenX Daffodils
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" />
                                          Kothwa, Rupaspur Nahar, Patna
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to GENX DAFFODILS, a premium
                                    residential project that redefines modern
                                    living in Patna. Located in the serene
                                    locality of Kothwa, near Rupaspur Nahar,
                                    this single-tower development offers the
                                    perfect blend of urban convenience and
                                    natural tranquility.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-shiva-details">
                                      GenX Shiva
                                    </Link>
                                  </h2>
                                  <div className="product-img-location">
                                    <ul>
                                      <li className="go-top">
                                        <Link to="/contact">
                                          <i className="flaticon-pin" /> Kolhua,Bairiya
                                          Golamber, Muzaffarpur
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>

                                  <p className="mt-3">
                                    Welcome to GENX SHIVA, a prestigious
                                    residential development that offers the
                                    perfect fusion of comfort, convenience, and
                                    contemporary living. Located in the prime
                                    area of Kolhua, near Bairiya Golamber,
                                    Muzaffarpur, GENX SHIVA is an exceptional
                                    single-tower apartment complex designed to
                                    cater to the modern homebuyer’s desires.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/product-3/2.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ltn__pagination-area text-center">
                  <div className="ltn__pagination">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-angle-double-left" />
                        </a>
                      </li>
                      <li>
                        <a href="#">1</a>
                      </li>
                      <li className="active">
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">...</a>
                      </li>
                      <li>
                        <a href="#">10</a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-angle-double-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OngoingProjectList;
