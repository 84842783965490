import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class UpcomingProjectList2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <div className="ltn__product-area ltn__product-gutter mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content ">
                  <div className="" id="liton_product_list">
                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                      <div className="row">
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/genx-daffodils.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-pearl-details">
                                      GenX Pearl City
                                    </Link>
                                  </h2>

                                  {/* <div className="product-img-location">
                                                                    <ul>
                                                                        <li className="go-top">
                                                                            <Link to="/contact"><i className="flaticon-pin" /> RPS Road, Patna</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}

                                  <p className="mt-3">
                                    GENX PEARL CITY is set to be a game-changer
                                    in the real estate landscape of Patna,
                                    offering luxury, convenience, and
                                    sustainability in one comprehensive package.
                                    With its premium location, world- class
                                    amenities, and exquisite design by Hafeez
                                    Contractor, it presents an exceptional
                                    opportunity for those looking for the
                                    perfect place to call home. Whether you’re
                                    looking for a 3BHK, 4BHK, or a penthouse,
                                    GENX PEARL CITY offers a lifestyle that’s
                                    beyond compare.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-platinum-details">
                                      GenX Platinum
                                    </Link>
                                  </h2>
                                  {/* <div className="product-img-location">
                                                                    <ul>
                                                                        <li className="go-top">
                                                                            <Link to="/contact"><i className="flaticon-pin" /> RPS Road, Patna</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}

                                  <p className="mt-3">
                                    GENX PLATINUM offers a prime opportunity for
                                    businesses looking for top-quality
                                    commercial space in Patna. With its modern
                                    design, flexible commercial units, basic
                                    amenities, and strategic location on Saguna
                                    - Khagaul Road, the mall is set to become a
                                    thriving business hub. Whether you’re
                                    looking to set up a retail shop, office, or
                                    showroom, GENX PLATINUM provides the perfect
                                    environment for success.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/product-3/2.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/genx-daffodils.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-silver-details">
                                      GEN X SILVER CREST
                                    </Link>
                                  </h2>
                                  {/* <div className="product-img-location">
                                                                    <ul>
                                                                        <li className="go-top">
                                                                            <Link to="/contact"><i className="flaticon-pin" /> Seikhpura More, Bailey Road, Patna</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}

                                  <p className="mt-3">
                                    GEN X Silver Crest is more than just a
                                    residential development; it is a gateway to
                                    a better quality of life in Patna. With its
                                    blend of modern design, luxurious amenities,
                                    and a prime location, it offers the perfect
                                    environment for both families and
                                    professionals. Whether you’re looking for a
                                    comfortable home or a sound investment, GEN
                                    X Silver Crest is the perfect choice for
                                    you.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-crystal-details">
                                      GenX Crystal
                                    </Link>
                                  </h2>
                                  {/* <div className="product-img-location">
                                                                    <ul>
                                                                        <li className="go-top">
                                                                            <Link to="/contact"><i className="flaticon-pin" /> RPS More, Patna</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}

                                  <p className="mt-3">
                                    Massa tempor nec feugiat nisl pretium.
                                    Egestas fringilla phasellus faucibus
                                    scelerisque eleifend donec Porta nibh
                                    venenatis cras sed felis eget velit aliquet.
                                    Neque volutpat ac tincidunt vitae semper
                                    quis lectus.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/product-3/2.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl +
                                        "assets/img/genx/genx-daffodils.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/genx-capital-details">
                                      GenX Capital
                                    </Link>
                                  </h2>
                                  {/* <div className="product-img-location">
                                                                    <ul>
                                                                        <li className="go-top">
                                                                            <Link to="/contact"><i className="flaticon-pin" /> DPS Road, Patna</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}

                                  <p className="mt-3">
                                    Massa tempor nec feugiat nisl pretium.
                                    Egestas fringilla phasellus faucibus
                                    scelerisque eleifend donec Porta nibh
                                    venenatis cras sed felis eget velit aliquet.
                                    Neque volutpat ac tincidunt vitae semper
                                    quis lectus.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ltn__product-item */}
                        <div className="col-lg-12">
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                            <div className="row">
                              <div className="col-lg-6 order-lg-1 order-2 pt-sm-5">
                                <div className="product-info">
                                  <h2 className="product-title go-top">
                                    <Link to="/jk-residency-details">
                                      J.K. Residency
                                    </Link>
                                  </h2>
                                  {/* <div className="product-img-location">
                                                                    <ul>
                                                                        <li className="go-top">
                                                                            <Link to="/contact"><i className="flaticon-pin" /> RPS More, Patna</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}

                                  <p className="mt-3">
                                    JK Residency represents the future of modern
                                    living in Patna. With its unparalleled
                                    design, luxurious amenities, and strategic
                                    location near Saguna More, Bailey Road, the
                                    project is set to become a landmark
                                    residential address. Whether you are looking
                                    for a comfortable family home, a smart
                                    investment opportunity, or a blend of both,
                                    JK Residency is the place to be.
                                  </p>

                                  {/* Amenities starts here */}
                                  <div className="property-details-amenities mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Air Conditioning
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Gym
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Barbeque
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Recreation
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="ltn__menu-widget">
                                          <ul>
                                            <li>
                                              <label className="checkbox-item">
                                                Refrigerator
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                            <li>
                                              <label className="checkbox-item">
                                                Window
                                                <input
                                                  type="checkbox"
                                                  defaultChecked="checked"
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* Amenities ends here */}
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-2 order-1">
                                <div className="product-img">
                                  <Link to="/project-details">
                                    <img
                                      src={
                                        publicUrl + "assets/img/product-3/2.jpg"
                                      }
                                      alt="#"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ltn__pagination-area text-center">
                  <div className="ltn__pagination">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fas fa-angle-double-left" />
                        </a>
                      </li>
                      <li>
                        <a href="#">1</a>
                      </li>
                      <li className="active">
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">...</a>
                      </li>
                      <li>
                        <a href="#">10</a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fas fa-angle-double-right" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpcomingProjectList2;
