import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
// import PortfolioV1 from './section-components/portfolio-v2';
import PortfolioV1 from './section-components/portfolio-v3';
import CallToActonV2 from './section-components/call-to-action-v2';
import BlogSlider from './blog-components/blog-slider-v1';
// import Sponsor from './section-components/sponsor-v2';
import Sponsor from './section-components/sponsor-v3';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';

const PortfolioV2 = () => {
    return <div>
        <NavbarV2 />
        {/* <PageHeader headertitle="Our Investor" subheader="Investor" /> */}
        <PageHeader headertitle="Banking Partner" subheader="Banking Partner" />
        <PortfolioV1 />
        <CallToActonV2 />
        <BlogSlider sectionClass="pt-120" />
        <Sponsor />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default PortfolioV2

