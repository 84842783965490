import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import ProjectDetailsSection from './shop-components/project-details';

const Project_Details = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Project Details" customclass="mb-0" />
        <ProductSlider />
        <ProjectDetailsSection />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Project_Details

