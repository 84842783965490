import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class PrivacyDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
                        <div className="ltn__team-details-member-info text-center mb-40">
                            <div className="team-details-img">
                                <img src={publicUrl + "assets/img/team/4.jpg"} alt="Team Member Image" />
                            </div>
                          
                        </div>
                    </div> */}
            <div className="col-lg-12">
              <div className="ltn__team-details-member-info-details">
                <h3>Privacy Policy</h3>
                <div>
                  <h5>Introduction </h5>
                  <p>
                    At Genx Real Estate, we prioritize the privacy and security
                    of our users. This Privacy Policy outlines how we collect,
                    use, disclose, and safeguard your personal information when
                    you interact with our website or services.
                  </p>
                </div>

                <div className="col-lg-12">
                  <ol>
                    <li>
                      <p>
                        <strong>Information We Collect</strong>
                      </p>
                      <ul>
                        <li>
                          <p>
                            <strong>Personal Information:</strong>
                            Name, contact details, and other information you
                            provide when registering or inquiring.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Browsing Data:</strong>
                            IP address, browser type, and pages visited.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Cookies:</strong>
                            To enhance your browsing experience.
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <p>
                        <strong>How We Use Your Information</strong>
                      </p>
                      <ul>
                        <li>
                          <p>To provide and improve our services.</p>
                        </li>
                        <li>
                          <p>
                            To communicate with you regarding inquiries or
                            transactions.
                          </p>
                        </li>
                        <li>
                          <p>
                            To ensure the security and functionality of our
                            website.
                          </p>
                        </li>
                        <li>
                          <p>To comply with legal requirements.</p>
                        </li>
                      </ul>
                    </li>


                    <li>
                      <p>
                        <strong>Sharing Your Information </strong>
                        We do not sell or share your personal information with third parties, except:
                      </p>
                      <ul>
                        <li>
                          <p>When required by law.</p>
                        </li>
                        <li>
                          <p>
                          With trusted service providers who assist in delivering our services.
                          </p>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <p>
                        <strong>Data Security </strong>
                        We implement robust security measures to protect your data from unauthorized access, alteration, or disclosure.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Your Rights </strong>
                        <ul>
                          <li>
                            <p>Access, update, or delete your personal information.</p>
                          </li>
                          <li>
                            <p>Opt-out of receiving marketing communications.</p>
                          </li>
                        </ul>
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Changes to This Policy </strong>
                        We may update this Privacy Policy periodically. Changes will be posted on this page with an updated effective date.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong>Contact Us </strong>
                        If you have any questions about this Privacy Policy, please contact us at:
                        <ul>
                          <li>
                            <p><strong>Email: </strong>genxinfracon01@gmail.com</p>
                          </li>
                          <li>
                            <p><strong>Phone: </strong>+91-9709051000, +91-9709052000</p>
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ol>
                </div>

                {/* <div className="row">
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Positions:</strong> Senior Property Seller
                        </li>
                        <li>
                          <strong>Experience:</strong> 10+ Years
                        </li>
                        <li>
                          <strong>Location:</strong> 13/A, NYC
                        </li>
                        <li>
                          <strong>Practice Area:</strong> Property Seller
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Email:</strong> example@example.com
                        </li>
                        <li>
                          <strong>Fax:</strong> +0123-456 789
                        </li>
                        <li>
                          <strong>Phone:</strong> +0123-456 789
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                {/* <hr /> */}
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum. Sed ut perspiciatis unde omnis
                  iste natus error sit voluptatem accusantium doloremque
                  laudantium, totam rem aperiam, eaque ipsa quae ab illo
                  inventore veritatis et quasi architecto beatae vitae dicta
                  sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                  aspernatur aut odit aut fugit, sed quia consequu ntur magni
                  dolores eos qui ratione voluptatem sequi nesciunt. Neque porro
                  quisquam est, qui dolorem ipsum quia dolor sit amet,
                  consectetur, adipisci velit, sed quia non numquam eius modi
                  tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyDetails;
