import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutUsService extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__service-area section-bg-1 pt-115 pb-70 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Services
                </h6>
                <h1 className="section-title">Our Core Services</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-house" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  {/* <h3><Link to="/service-details">Property Management</Link></h3> */}
                  <h3>
                    <Link to="#">Property Management</Link>
                  </h3>
                  <p>
                    Simplify property ownership with our expert management
                    services, ensuring smooth operations and maximum value.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-mortgage" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  {/* <h3><Link to="/service-details">Mortgage Service</Link></h3> */}
                  <h3>
                    <Link to="#">Mortgage Service</Link>
                  </h3>

                  <p>
                    Find the best financing options with our comprehensive
                    mortgage solutions tailored to your needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-operator" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  {/* <h3><Link to="/service-details">Consulting Service</Link></h3> */}
                  <h3>
                    <Link to="#">Consulting Service</Link>
                  </h3>
                  <p>
                    Make informed real estate decisions with our expert guidance
                    and market insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-house-1" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  {/* <h3><Link to="/service-details">Home Buying</Link></h3> */}
                  <h3>
                    <Link to="#">Home Buying</Link>
                  </h3>

                  <p>
                    Turn your dream of owning a home into reality with our
                    seamless and personalized home-buying process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-house-3" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  {/* <h3><Link to="/service-details">Home Selling</Link></h3> */}
                  <h3>
                    <Link to="#">Home Selling</Link>
                  </h3>
                  <p>
                    Sell your property at the best value with our strategic
                    marketing and expert support.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-official-documents" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Property Documentation</Link>
                  </h3>
                  <p>
                    Leave the paperwork to us. We ensure all your property
                    documents are accurate, secure, and hassle-free.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsService;
