import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class HomeV2Video extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'

        return <div className="ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg">
            <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
                {/* HTML5 VIDEO */}
                {/* <iframe
                    id='myVideo'
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/nQ8LSFE-efM?autoplay=1&mute=1&loop=1&playlist=nQ8LSFE-efM" 
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe> */}
                <video autoPlay muted loop id="myVideo">
                    <source src='https://www.signatureglobal.in/video/mainbannervideo24.mp4' type='video/mp4' />
                </video>
                <div style={{
                    height: '100vh'
                }} className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal--- ltn__slide-item-3 bg-image bg-overlay-theme-black-60"
                // data-bs-bg={publicUrl+"assets/img/slider/13.jpg"}
                >

                </div>
            </div>
        </div>

    }
}

export default HomeV2Video