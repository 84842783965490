import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-grid-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';
import ProjectList from './shop-components/projectList';
import OngoingProjectList from './shop-components/OngoingProjectList';

const OngoingProjects = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Ongoing Projects" />
        <OngoingProjectList />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default OngoingProjects

