import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import ContactForm from './section-components/contact-form';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import NavbarV2 from './global-components/navbar-v2';

const ContactV2 = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Request a callback" subheader="Request" />
        <ContactInfo />
        <div className='mb-5'>
        <ContactForm />
        </div>
        
        {/* <Map /> */}
        <div className='mt-5 pt-5'>
            <div className='mt-5 pt-5'>
            <CallToActionV1 />
            </div>
        </div>
       
        <Footer />
    </div>
}

export default ContactV2

